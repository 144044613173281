<template>

  <!-- {{user}} -->
  <div class="col-lg-7 col-md-7">
    <EditPost :isCalendar="false"></EditPost>
    <!-- 推薦不顯示新增貼文 -->
    <div class="col-sm-12">
      <tab-content id="pills-tabContent-2">
        <tab-content-item :active="true" id="profile-feed" aria-labelled-by="pills-feed-tab">
          <AddSocialPost :showForm="true"></AddSocialPost>
        </tab-content-item>
      </tab-content>
    </div>
    <div id="SocialRecommendedCreatorsMobile" class="col-sm-12">
      <SocialRecommendedCreatorsMobile></SocialRecommendedCreatorsMobile>
    </div>
    <tab-content id="pills-tabContent-2" v-infinite-scroll="getRows" :infinite-scroll-disabled="commomData.noResult">
      <tab-content-item :active="true" id="profile-feed" aria-labelled-by="pills-feed-tab">
        <SocialPost :post="list" :settions="settions" :showSettions="false" :previewUser="{}" />
      </tab-content-item>
    </tab-content>
  </div>

  <!-- 廣告區塊隱藏  -->
  <SocialRecommendedCreators></SocialRecommendedCreators>
  <div v-if="!commomData.noResult" class="col-sm-12 text-center">
    <img src="../../../assets/images/page-img/page-load-loader.gif" alt="loader" style="height: 100px" />
  </div>
</template>
<script>
import { socialvue } from '../../../config/pluginInit'
import SocialPost from './Components/SocialPost'
import AddSocialPost from './Components/AddSocialPost'
import EditPost from './Components/EditPost'

import { mapGetters } from 'vuex'
import { commomData } from '@/utils/commonVmodel'
import { getPost } from '@/api/post'
import { getSettings } from '@/api/settings'
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useSocket } from '@/utils/useSocket'
export default {
  name: 'SocialApp',
  components: { AddSocialPost, SocialPost, EditPost },
  setup(props) {
    console.log(props)
    const store = useStore()
    const { initSocket } = useSocket()
    const settions = ref({})
    const getUserSettions = () => {
      getSettings()
        .then(res => {
          settions.value = res
        })
        .catch(err => {
          console.log(err)
        })
    }
    onMounted(() => {
      getUserSettions()
      store
        .dispatch('User/fetcChatListAction')
        .then(result => {
          console.log(
            'SocialApp.vue',
            'User/fetcChatListAction => initSocket',
            result
          )
          initSocket()
        })
        .catch(err => {
          console.log(err)
        })
    })
    return {
      settions
    }
  },
  computed: {
    ...mapGetters({
      user: 'User/userState',
      token: 'User/tokenState',
      isPlan: 'User/isPlanState',
      planDate: 'User/planDateState'
    })
  },
  created() {
    socialvue.index()
    // 寬度 1230 才執行，因為不知道為何會跑兩次
    // alert(window.innerWidth)
    if (window.innerWidth >= 1230) {
      this.getRows()
    }
  },
  mounted() {},
  data() {
    return {
      list: [],
      primaryKey: 'created_at',
      commomData: commomData(),
      isFetching: false // 新增變量來追蹤是否正在請求
    }
  },
  methods: {
    async getRows() {
      if (this.commomData.noResult || this.isFetching) {
        return false
      }

      this.isFetching = true // 鎖定請求
      this.commomData.listLoader = true
      this.commomData.condition.order_by = this.primaryKey
      this.commomData.condition.post_type = 1

      await getPost(this.commomData.condition)
        .then(res => {
          if (this.commomData.condition.page === 1) {
            this.list = res.data
            this.commomData.condition.page = 2
            this.commomData.listLoader = false
            if (res.total === 0) {
              this.commomData.noResult = true
            }
          } else if (res.total > this.list.length) {
            this.list.push(...res.data)
            this.commomData.condition.page = this.commomData.condition.page + 1
            this.commomData.listLoader = false
          } else if (res.total === this.list.length) {
            this.commomData.noResult = true
            this.commomData.listLoader = false
          } else {
            this.commomData.noResult = true
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isFetching = false // 請求完成後解鎖
        })
    }
  }
}
</script>
